import React, { useState } from "react"
import Layout from "../../components/layout"
import { Spinner} from "react-bootstrap"
import { quizkyCrampeteAPI } from "../../config"
import {Field, Form, Formik} from "formik"
import * as Yup from "yup"

const BlogSideBarFormSchema = Yup.object().shape({
    name: Yup.string().required("Please enter your full name"),
    email: Yup.string().required("Please enter a valid email").email("Please enter a valid email"),
    number: Yup.number().required("Please enter a valid number").positive("Please enter a valid number").integer("Please enter a valid number"),
    course: Yup.string().required("Please select a course"),
  })

const NewsRoomEvents = ({ data }) => {

const queryString = global.window?.location.search
  let urlParams = new URLSearchParams(queryString);

  const [utmParams] = useState({
    utmSource: urlParams.get("utm_source") || "",
  })
  let utmSource = utmParams.utmSource ? utmParams.utmSource : 'organic';

  const [meta] = useState({
  
  })

  const [success, setSuccess] = useState({
    message: ''
  })

  const [formError, setFormError] = useState({
    message: ""
  })

  const [spinner, setSpinner] = useState(false);

    const handleSubmit = values => {
      const {name,email,number,course} = values;

      const opts = {"companyCode": "crampete","accountId": "master","studentName": name,"emailId": email,"mobileNumber": number,"referenceCourseName": course, "source": utmSource,}
      
        setSpinner(true);

        setTimeout(() => {
          fetch(`${quizkyCrampeteAPI}/common-lead/zoho`, {
            method: 'post',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(opts)
            }).then(function(response) {
              return response.json();
            }).then(function(data) {
              if(data.success) {
                setSuccess((prevState) => {
                  return {...prevState, message: 'Thank you, we\'ve received your details.', }
                });
              } else {
                setFormError((prevState) => {
                  return {...prevState, message: 'Something went wrong, please try again!'}
                });
              }
            }).catch((e) => console.error('Error:',e));
            setSpinner(false);
        }, 1000);
    }
  
return (
    <Layout>
      <div className="container mt-5"> 
        <div className="row">
          <div className="col-md-9 pr-md-5">
            <div>
              <p>
                <span className="font-weight-bold">Crampete</span>, a pioneer in the EdTech space who has been
                successfully equipping students with in demand technology
                training for the last four years, <span className="font-weight-bold">Crampete</span> and <span className="font-weight-bold">DMI College of
                Engineering</span>  have joined hands in imparting knowledge on Data
                Science to the students.
              </p>
              <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/newsroom/DMI-CSE-II-3.jpeg" alt="" className="mt-2" />
              <p className="mt-5">
                The boot camp happened on December 3, 2022, at the premises of
                DMI College of Engineering. There was an ample amount of
                interest among the students, and the boot camp saw about 200
                students actively participating and getting the idea of data
                science, its importance, and the opportunities available around
                the globe.
              </p>
              <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/newsroom/DMI-CSE-II-2.jpeg" alt="" className="mt-2" />
              <p className="mt-5">
              <span className="font-weight-bold"> Mr. Swaminathan Umamaheswaran </span>from Crampete has given in depth
                information about "Why Data Science?" and <span className="font-weight-bold">Mr. Aparyay Kumar</span>, a
                data scientist, explained about the needs of data scientists in
                the real world and how Data Science is changing the landscape of
                analysis and artificial intelligence.
              </p>
              <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/newsroom/DMI-CSE-II+.jpeg" alt=""  className="mt-2" />
            </div>
          </div>
          <div className="col-md-3">
          <div className="blog-form-wrapper">
                <div className="blog-form-wrapper-inner">
                  <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/blog/lead-form-image-person.png" alt="Lead Form Person"/>
                  <p>Accelerate Your Career with <span>Crampete</span></p>
                  <Formik initialValues={{name: "", email: "", number: "", course: ""}} onSubmit={handleSubmit} validationSchema={BlogSideBarFormSchema}>
                      {({ errors, touched }) => (
                        <Form>
                          <div className={`${touched.name && errors.name ? 'blogFormErr' : ''}`}>
                            <Field autoComplete="off" name="name" placeholder="Full Name" />
                          </div>
                          <div className={`${touched.email && errors.email ? 'blogFormErr' : ''}`}>
                            <Field autoComplete="off" name="email" placeholder="Email Address" />
                          </div>
                          <div className={`${touched.number && errors.number ? 'blogFormErr' : ''}`}>
                            <Field autoComplete="off" name="number" placeholder="Phone No." />
                          </div>
                          <div className={`${touched.course && errors.course ? 'blogFormErr' : ''}`}>
                            <Field as="select" name="course">
                              <option value="" disabled selected>Select Course</option>
                              <option value="Full Stack Development">Full Stack Development</option>
                              <option value="Data Science">Data Science</option>
                              <option value="Digital Marketing">Digital Marketing</option>
                              <option value="Front End">Front End</option>
                              <option value="Back End">Back End</option>
                              <option value="Other Courses">Other Courses</option>
                            </Field>
                          </div>
                          {!spinner ? <button className="btn btn-primary" type="submit">Submit</button> : <button className="please-wait btn btn-primary">Please wait <Spinner animation="border" variant="light" size="sm" /></button>}
                          {success.message && <div id="blogLeadResult">{success.message}</div>}{formError.message && <div id="blogLeadValidation">{formError.message}</div>}
                        </Form>
                      )}
                  </Formik>
                </div>
              </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NewsRoomEvents;
